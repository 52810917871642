<template>
  <footer class="footer">
    <div class="container">
      <div class="footer__content">
        <div class="footer-logo">
          <router-link
            :to="{ name: 'Home' }"
            class="footer-logo__img"
          ></router-link>
          <div class="footer-logo__text">
            Путешествия делают нашу жизнь лучше!
          </div>
        </div>
        <ul class="footer-nav">
          <li class="footer-nav__item">
              <a href="/booking" class="footer-nav__link">
                Бронирование
              </a>
            </li>
          <li class="footer-nav__item">
            <router-link :to="{ name: 'About' }" class="footer-nav__link">
              О проекте
            </router-link>
          </li>
          <li class="footer-nav__item">
            <router-link :to="{ name: 'Baikal' }" class="footer-nav__link">
              О Байкале
            </router-link>
          </li>
          <li class="footer-nav__item">
            <router-link :to="{ name: 'ARQuest' }" class="footer-nav__link">
              AR квест
            </router-link>
          </li>
          <li class="footer-nav__item">
            <router-link :to="{ name: 'News' }" class="footer-nav__link">
              Новости
            </router-link>
          </li>
          <li class="footer-nav__item">
            <router-link :to="{ name: 'Partners' }" class="footer-nav__link">
              Партнерам
            </router-link>
          </li>
          <li class="footer-nav__item">
            <router-link :to="{ name: 'Contacts' }" class="footer-nav__link">
              Контакты
            </router-link>
          </li>
        </ul>
        <div class="footer-app">
          <a href="javascript:void(0)" class="footer-app__link">
            <img src="@/assets/img/ios.svg" alt="App Store" />
          </a>
          <a href="javascript:void(0)" class="footer-app__link">
            <img src="@/assets/img/android.png" alt="Google Play" />
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "AppFooter",
};
</script>
