<template>
  <div>
    <header
      class="header"
      :class="{
        'header--fixed': isBurgerOpen,
        'header--dark': isDarkTheme,
        'header--absolute': !isDarkTheme,
      }"
    >
      <div class="container">
        <div class="header__content">
          <router-link
            :to="{ name: 'Home' }"
            class="header__logo"
            @click.native="isBurgerOpen = false"
          ></router-link>
          <ul class="header-nav">
            <li class="header-nav__item">
              <a href="/booking" class="header-nav__link">
                <span class="header-nav__item-letter" v-for="(letter, i) of 'Бронирование'" :key="i">{{ letter }}</span>
              </a>
            </li>
            <li class="header-nav__item">
              <router-link :to="{ name: 'About' }" class="header-nav__link">
                О проекте
              </router-link>
            </li>
            <li class="header-nav__item">
              <router-link
                :to="{ name: 'Baikal' }"
                href="javascript:void(0)"
                class="header-nav__link"
              >
                О Байкале
              </router-link>
            </li>
            <li class="header-nav__item">
              <router-link :to="{ name: 'ARQuest' }" class="header-nav__link">
                AR квест
              </router-link>
            </li>
            <li class="header-nav__item">
              <router-link :to="{ name: 'Partners' }" class="header-nav__link">
                Партнерам
              </router-link>
            </li>
            <li class="header-nav__item">
              <router-link :to="{ name: 'News' }" class="header-nav__link">
                Новости
              </router-link>
            </li>
            <li class="header-nav__item">
              <router-link :to="{ name: 'Contacts' }" class="header-nav__link">
                Контакты
              </router-link>
            </li>
          </ul>
          <div class="header-app">
            <a class="header-app__link" href="https://goguide.ru/install">
              <button class="header-app__btn">Скачать приложение</button>
            </a>
          </div>
          <!-- <BurgerMenu /> -->
          <div
            class="header-burger"
            :class="{ 'header-burger--open': isBurgerOpen }"
            @click="toggleBurger"
          >
            <div class="header-burger__bar header-burger__bar--1"></div>
            <div class="header-burger__bar header-burger__bar--2"></div>
            <div class="header-burger__bar header-burger__bar--3"></div>
          </div>
        </div>
      </div>
    </header>
    <div>
      <ul
        class="header-burger__nav"
        :class="{ 'header-burger__nav--open': isBurgerOpen }"
      >
        <li @click="toggleBurger">
          <a href="/booking">
            <span class="header-burger__nav-letter" v-for="(letter, i) of 'Бронирование'" :key="i">{{ letter }}</span>
          </a>
        </li>
        <li @click="toggleBurger">
          <router-link :to="{ name: 'About' }"> О проекте </router-link>
        </li>
        <li @click="toggleBurger">
          <router-link :to="{ name: 'Baikal' }">О Байкале</router-link>
        </li>
        <li @click="toggleBurger">
          <router-link :to="{ name: 'ARQuest' }">AR квест</router-link>
        </li>
        <li @click="toggleBurger">
          <router-link :to="{ name: 'Partners' }">Партнерам</router-link>
        </li>
        <li @click="toggleBurger">
          <router-link :to="{ name: 'News' }"> Новости </router-link>
        </li>
        <li @click="toggleBurger">
          <router-link :to="{ name: 'Contacts' }">Контакты</router-link>
        </li>
        <li class="header-burger__app" @click="toggleBurger">
          <a href="javascript:void(0)">
            <img src="@/assets/img/ios.svg" alt="App Store" />
          </a>
        </li>
        <li class="header-burger__app" @click="toggleBurger">
          <a href="javascript:void(0)">
            <img src="@/assets/img/android.png" alt="Google Play" />
          </a>
        </li>
      </ul>
      <div
        class="header-burger__bg"
        :class="{ 'header-burger--open': isBurgerOpen }"
      ></div>
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";

export default {
  name: "AppHeader",
  data() {
    return {
      isBurgerOpen: false,
    };
  },
  computed: {
    isDarkTheme() {
      return this.$route.name !== "Home";
    },
  },
  methods: {
    toggleBurger() {
      this.isBurgerOpen = !this.isBurgerOpen;
    },
  },
  mounted() {
    [".header-nav__item-letter", ".header-burger__nav-letter"].forEach(selector => gsap.to(selector, {
      duration: .3,
      delay: 1.5,
      repeatDelay: 2.5,
      repeat: -1,
      stagger: .03,
      keyframes: [{
        scaleX: 1.4,
        scaleY: 2,
        duration: .5
      },
      {
        scaleX: 1,
        scaleY: 1,
        duration: 1
      }]
    }));
  },
};
</script>
