<template>
  <div class="guide-section js-trigger-path" data-name="app">
    <div class="container">
      <div class="guide-content">
        <div class="guide-content__row">
          <div class="guide-content__col">
            <h2 class="guide-title">
              GO AND GUIDE 360 Одно приложение — весь Байкал
            </h2>
          </div>
          <div class="guide-content__col">
            <div class="guide-descr">
              Ваш комфорт и богатые впечатления — наш приоритет. В GO AND GUIDE
              360 вы имеете все инструменты для знакомства с регионом — от
              заказа еды до покорения байкальских вод. Ищете гостиницу у озера
              Байкал? Вы сможете решить это в пару кликов. Хотите посетить
              достопримечательности? Вся информация о них уже у вас под рукой. А
              кроме того — завораживающие маршруты, экскурсии и живая
              коммуникация с героями в дополненной реальности! Приложение
              доступно для смартфонов в Google Play и Apple Store.
            </div>
          </div>
        </div>

        <div class="guide-app">
          <a href="javascript:void(0)" class="guide-app__app">
            <img src="@/assets/img/ios.svg" alt="" />
          </a>
          <a href="javascript:void(0)" class="guide-app__app">
            <img src="@/assets/img/android.png" alt="" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GuideApp",
};
</script>
